import React           from 'react';
import PropTypes       from 'prop-types';
import TextBadge       from "./TextBadge";
import { generateKey } from '../../../../../utils/CommonUtils';

const TextBadgesList = props => (
	Array.isArray(props.textBadges) && props.textBadges.length > 0 ? (
		<div className={'grid-2__tile__badges__text-badges-list' + (props.alignRight ? ' -align-right' : '')}>
			{props.textBadges.map((textBadge, index) => <TextBadge key={generateKey(null, index)} {...textBadge} />)}
		</div>
	) : null
);

TextBadgesList.propTypes = {
	textBadges: PropTypes.arrayOf(PropTypes.shape(TextBadge.propTypes)),
	alignRight: PropTypes.bool,
};

TextBadgesList.defaultProps = {
	textBadges: [],
	alignRight: false,
};

export default TextBadgesList;
