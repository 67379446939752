import React           from 'react';
import PropTypes       from 'prop-types';
import Flux            from "../../../../../flux/Flux";
import { generateKey } from '../../../../../utils/CommonUtils';

const {Styles, FontColors} = Flux.Constants.Grid2.TextBadge;

const BADGE_STYLE_CLASSNAME_MAPPING = {
	[Styles.DEFAULT]:   '-default',
	[Styles.SUCCESS]:   '-success',
	[Styles.HIGHLIGHT]: '-highlight',
	[Styles.SPECIAL]:   '-special',
	[Styles.GOLD]:      '-gold',
	[Styles.SHAPE]:     '-shape',
};

const PART_CLASSNAME_CROSSED = '-crossed';

const FONT_COLOR_CLASSNAME_MAPPING = {
	[FontColors.WHITE]:     '-color-white',
	[FontColors.BLACK]:     '-color-black',
	[FontColors.SUCCESS]:   '-color-success',
	[FontColors.HIGHLIGHT]: '-color-highlight',
	[FontColors.SPECIAL]:   '-color-special',
};

const TextBadge = props => (
	<div className={'grid-2__tile__badges__text-badges-list__text-badge' + getAdditionalBadgeClassNameByStyle(props.style)}>
		{props.parts.map((part, index) => (
			<span key={generateKey(null, index)} className={'grid-2__tile__badges__text-badges-list__text-badge__part' + getAdditionalPartClassNames(part)}>
				{part.icon && <i className={`icon grid-2__tile__badges__text-badges-list__text-badge__icon ${part.icon}`}></i>}
				{part.text}
			</span>
		))}
	</div>
);

function getAdditionalBadgeClassNameByStyle(style) {
	const styleClass = BADGE_STYLE_CLASSNAME_MAPPING[style];
	return styleClass ? ` ${styleClass}` : '';
}

function getAdditionalPartClassNames(part) {
	let classes = '';

	if (part.crossed) {
		classes += ` ${PART_CLASSNAME_CROSSED}`;
	}

	if (part.color) {
		const colorClass = FONT_COLOR_CLASSNAME_MAPPING[part.color];

		if (colorClass) {
			classes += ` ${colorClass}`;
		}
	}

	return classes;
}

TextBadge.propTypes = {
	parts: PropTypes.arrayOf(PropTypes.shape({
		text:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		icon:    PropTypes.string,
		color:   PropTypes.string,
		crossed: PropTypes.bool,
	})),
	style: PropTypes.oneOf(Object.values(Flux.Constants.Grid2.TextBadge.Styles)).isRequired,
};

export default TextBadge;