import React                   from 'react';
import PropTypes               from 'prop-types';
import {TileDisplayConfigType} from '../../../Grid2';
import Grid2Helper             from '../../../../../utils/Grid2Helper';

const Badges = props => (
	<div className={'grid-2__tile__badges ' + Grid2Helper.getExtendedSizeClasses(props.displayConfig.tileSize, props.displayConfig.columns)}>
		{props.children}
	</div>
);

Badges.propTypes = {
	displayConfig: TileDisplayConfigType.isRequired,
	children:      PropTypes.node,
};

export default Badges;
