import React     from 'react';
import PropTypes from "prop-types";

const BadgesColumn = props => (
	<div className={'grid-2__tile__badges-column' + (props.grow ? ' -grow' : '') + (props.shrink ? ' -shrink' : '') + (props.noPadding ? ' -no-padding' : '')}>
		{props.children}
	</div>
);

BadgesColumn.propTypes = {
	grow:      PropTypes.bool,
	shrink:    PropTypes.bool,
	children:  PropTypes.node,
	noPadding: PropTypes.bool,
};

export default BadgesColumn;
